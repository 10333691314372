<template>
	<div id="pageList" class="el-content">
		<a-tabs defaultActiveKey @change="changeTab">
			<template #tabBarExtraContent>
				<router-link to="/set/slide/edit">
					<a-button type="primary" v-has="{action:'/set/slide/edit'}" v-if="active== 3">
						<i class="ri-add-line"></i>添加轮播图
					</a-button>
				</router-link>
			</template>
			<a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tab">
				<!-- 自定义页面列表 -->
				<div v-if="item.key == 2">
					<a-space class="mb12">
						<a-input-search
							v-model:value="key"
							placeholder="名称"
							enter-button
							style="width:400px;"
							@search="getPageList(1,info.limit)"
						/>
						<router-link to="/set/customPage/homePage">
							<a-button type="primary" v-has="{action:'/set/customPage/homePage'}">
								<i class="ri-add-line"></i>新增页面
							</a-button>
						</router-link>
					</a-space>
					<a-table row-key="id" :pagination="false" :data-source="info.list" :columns="[
						{title:'ID',dataIndex:'id'},
						{title:'页面名称',dataIndex:'page_name'},
						{title:'页面类型',dataIndex:'page_type',slots:{customRender:'page_type'}},
						{title:'是否默认',dataIndex:'is_default',slots:{customRender:'is_default'}},
						{title:'创建时间',dataIndex:'create_time'},
						{title:'最后更新时间',dataIndex:'update_time'},
						{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
					]" class="mt12">
						<template #is_default="{record}">
							<a-tag color="#00CC66" v-if="record.is_default ==1 ">显示</a-tag>
							<a-tag color="#999" v-if="record.is_default ==0 ">隐藏</a-tag>
						</template>
						<template #page_type="{record}">
							<a-tag color="#00CC66" v-if="record.page_type ==1 ">首页</a-tag>
						</template>
						<template #action="{record}">
							<a-space>
								<router-link :to="{ path: '/set/customPage/homePage', query: { id: record.id }}">
									<kd-button type="primary" 
										title="编辑" 
										icon="ri-edit-line" 
										v-has="{action:'/set/customPage/homePage'}">
									</kd-button>
								</router-link>
								<kd-button type="success" 
									v-if="record.is_default==0" 
									title="设为默认" 
									icon="ri-star-line" 
									v-has="{action:'wxapp_set_homePage_default'}"  
									@click="handleCusotmPage('default',record.id)">
								</kd-button>
								<kd-button type="purple" 
									title="复制" 
									icon="ri-file-copy-2-line" 
									v-has="{action:'wxapp_set_homePage_copy'}"  
									@click="handleCusotmPage('copy',record.id)">
								</kd-button>
								<kd-button type="danger" 
									v-if="record.is_default==0" 
									title="删除" 
									icon="ri-delete-bin-5-line" 
									v-has="{action:'wxapp_set_homePage_del'}"  
									@click="handleCusotmPage('delete',record.id)">
								</kd-button>
							</a-space>
						</template>
					</a-table>
					<div class="pager">
						<a-pagination
							show-size-changer
							:default-current="info.page"
							:total="info.count"
							@showSizeChange="(p,e)=>{getCurrentList(info.page,e)}"
							@change="(e)=>{getCurrentList(e,info.limit)}"
						/>
					</div>
				</div>
				<!-- 主题设置 -->
				<div v-if="item.key == 1">
					<com-power-form :form-data="[
						{label:'导航字体颜色',type:'radio',value:'',key:'theme_nav_color',dataType:'string',
							options:[
								{label:'白色',value:'#ffffff'},
								{label:'黑色',value:'#000000'},
							]
						},
						{label:'导航背景颜色',type:'color',value:'',key:'theme_nav_back_color',dataType:'string'},
						{label:'主题颜色',type:'color',value:'',key:'theme_color',dataType:'string'},
						{label:'辅助颜色',type:'color',value:'',key:'theme_assit_color',dataType:'string'},
					]"></com-power-form>
				</div>
				<div v-if="item.key == 4">
					<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
						<a-form-item label="背景颜色">
							<kd-color :fixd="true" v-model="tabbarForm.tabbar_bg"></kd-color>
						</a-form-item>
						<a-form-item label="默认字体颜色">
							<kd-color :fixd="true" v-model="tabbarForm.tabbar_color"></kd-color>
						</a-form-item>
						<a-form-item label="选中的字体颜色">
							<kd-color :fixd="true" v-model="tabbarForm.tabbar_select_color"></kd-color>
						</a-form-item>
						<a-form-item label="效果示意图" v-if="tabbarForm.tabbar_list">
							<div class="f12">点击选中菜单,可对选中的菜单进行编辑;双击可删除点击菜单;可拖动对菜单进行排序</div>
							<div class="tabbar-cont">
								<draggable v-model="tabbarForm.tabbar_list"  item-key="id" :component-data="{name:'fade'}">
									<template #item="{element,index}">
										<div
											class="tabbar-cont-item" 
											:style="{width:setTabbarWidth+'%',color:tabbar_index == index ? tabbarForm.tabbar_select_color:tabbarForm.tabbar_color}" 
											@click="tabbar_index = index"
											@dblclick="handleTabbar('delete',index)"
										>
												<i :class="element.icon"></i>
												<div>{{element.text}}</div>
										</div>
									</template>
								</draggable>
							</div>
							<div class="tabbar-cont-add" @click="handleTabbar('add')"><i class="ri-add-line"></i></div>
						</a-form-item>

						<div class="tabbar-cont-ite-edit" v-if="tabbarForm.tabbar_list">
							<a-form-item label="文字描述">
								<a-input v-model:value="tabbarForm.tabbar_list[tabbar_index].text"></a-input>
							</a-form-item>
							<a-form-item label="默认图标">
								<com-reminxi-icon 
									:value="tabbarForm.tabbar_list[tabbar_index].icon" 
									@changeValue="(e)=>{tabbarForm.tabbar_list[tabbar_index].icon = e}">
								</com-reminxi-icon>
							</a-form-item>
							<a-form-item label="选中图标">
								<com-reminxi-icon 
									:value="tabbarForm.tabbar_list[tabbar_index].selectIcon" 
									@changeValue="(e)=>{tabbarForm.tabbar_list[tabbar_index].selectIcon = e}">
								</com-reminxi-icon>
							</a-form-item>
							<a-form-item label="链接页面">
								<a-select v-model:value="tabbarForm.tabbar_list[tabbar_index].path">
									<a-select-option :value="val.path" v-for="(val,ind) in pathList" :key="ind">
										{{val.name}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<a-form-item :wrapper-col="{ offset: 4 }">
							<a-button type="primary" @click="submitSave">保存</a-button>
						</a-form-item>
					</a-form>
				</div>
				<div v-if="item.key == 5">
					<custom-user-center></custom-user-center>	
					<!-- <a-form ref="cnterPage" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
						<div class="tips">
							<p>提示</p>
							<p>1、若客户端个人中心出现功能按钮点击不了时，请先重置一下页面，并重新打开小程序重试！</p>
						</div>
						<a-form-item>
							<div class="mini-center-li" v-for="(item,index) in center" :key="index">
								<div class="mini-center-li-menu">
									<span class="iconfont" :class="item.icon"></span>
									<a-input v-model:value="item.name"></a-input>
								</div>
								<div class="mini-center-li-wxapp">小程序：<a-switch v-model:checked="item.wxapp_show"></a-switch></div>
								<div class="mini-center-li-app">APP：<a-switch v-model:checked="item.app_show"></a-switch></div>
							</div>
						</a-form-item>
						<a-form-item>
							<a-space>
								<a-button type="primary" @click="submitSave" >保存</a-button>
								<a-button type="danger" @click="resetCenterPage">重置</a-button>
							</a-space>
						</a-form-item>
					</a-form> -->
				</div>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
import draggable from 'vuedraggable'
import kdColor from '@/components/public/kd-color.vue'
import comReminxiIcon from '@/components/public/com-reminxi-icon.vue'
import customUserCenter from '@/components/layout/customPage/custom-user-center.vue'
import common from "@/api/common.js"
import set from "@/api/set.js"
import { computed, reactive, toRefs } from 'vue'
import tool from '@/util/tool.js'
import rh from '@/router/routerHandle.js'
import comPowerForm from '@/components/form/com-power-form.vue'
export default{
	components:{
		draggable,
		kdColor,
		comReminxiIcon,
		comPowerForm,
		customUserCenter
	},
	setup(){
		const _d = reactive({
			active:"2",
			tabs:[],
			key:"",
			info:{
				page:1,
				limit:10,
				count:0,
				list:[],
			},
			
			tabbar_index:0,
			tabbarForm:{
				tabbar_bg:'#ffffff',			//背景颜色
				tabbar_color:'#999999'	,		//字体的颜色
				tabbar_select_color:'#46c706',	//选中的字体颜色
				tabbar_list:[
					{icon:'ri-home-smile-line',selectIcon:'ri-home-smile-fill',text:'首页',path:'pages/index/index'},
					{icon:'ri-apps-line',selectIcon:'ri-apps-fill',text:'租地',path:'pages/land/index'},
					{icon:'ri-vip-crown-line',selectIcon:'ri-vip-crown-fill',text:'认养',path:'sub_package/adoptV2/pages/index'},
					{icon:'ri-shopping-cart-2-line',selectIcon:'ri-shopping-cart-2-fill',text:'集市',path:'pages/shop/index'},
					{icon:'ri-user-3-line',selectIcon:'ri-user-3-fill',text:'我的',path:'pages/user/index'},
				]
			},
			pathList:tool.getAppPath(false),
			center:tool.getWxappCenterPath(),
		})

		_d.tabs = rh.getCuurentTabs("layout")

		const setTabbarWidth = computed(()=>{
			let len = _d.tabbarForm.tabbar_list.length
			return (100/len).toFixed(2)
		})

		if( _d.tabs.length > 0 ) changeTab(_d.tabs[0].key)

		function changeTab(_a){
			_d.active = _a
			if( _a == 4 || _a == 5 ) getSetData()
			if( _a == 2) getCurrentList(1,10)
		}


		//获取全局的设置信息
		function getSetData(){
			let _a = _d.active , keys = []
			if( _a == 4 ) keys = Object.keys(_d.tabbarForm)
			if( _a == 5 ) keys = ['center_page'];
			if(_a == 2 ){
				getCurrentList(1,10)
			}
			set.getFarmSet(keys).then(res=>{
				if(_a == 4 ) {
					_d.tabbarForm.tabbar_bg = res.tabbar_bg || "#ffffff"
					_d.tabbarForm.tabbar_color = res.tabbar_color || "#999999"
					_d.tabbarForm.tabbar_select_color = res.tabbar_select_color || "#46c706"
					if( res.tabbar_list ) _d.tabbarForm.tabbar_list = JSON.parse(res.tabbar_list)
					_d.tabbarForm.tabbar_list.forEach((item,index)=>{
						item.id = index
					})
				}
				if( _a == 5 && res.center_page ){
					_d.center = JSON.parse(res.center_page)
				}
			})
		}

		function submitSave(){
			let _a = _d.active , param = null
            if( _a == 4 ) param = _d.tabbarForm
			if( _a == 5 ) param = { center_page : _d.center }
            set.setFarmSet(param)
		}

		function getCurrentList(page,limit){
			let param = { page,limit }
			if( _d.active == 2 ){	//获取自定义页面数据
				if( _d.key ) param.key = _d.key
				common.getDataList("getPageList",param).then(res=>{
					_d.info = { limit , ...res}
				})
			}
		}
		//自定义页面操作
		function handleCusotmPage(sence,id){
			switch(sence){
				case "default":	//设置默认页面
					common.addOrEditCustomPage('set-default',{is_default:1,id}).then(()=>{
						getCurrentList(_d.info.page,_d.info.limit)
					})
					break;
				case "copy":	//复制页面
					common.copyCustomPage(id).then(()=>{
						getCurrentList(_d.info.page,_d.info.limit)
					})
					break;
				case "delete":	//删除页面
					common.deleteDataList(id,30,"确认要删除该页面吗?").then(()=>{
						getCurrentList(_d.info.page,_d.info.limit)
					})
					break;
				default:
					break
			}
		}

		//tabbar新增/删除操作
		function handleTabbar(sence,index=0){
			let tabLen = _d.tabbarForm.tabbar_list.length
			if( sence == 'add' ){		//新增
				if( tabLen >=6 ){
					tool.message("底部tabbr最多6个","warning");
					return
				}
				let id = tabLen+1
				_d.tabbarForm.tabbar_list.push(
					{icon:'ri-user-3-line',selectIcon:'ri-user-3-fill',text:'title',path:'',id}
				);
			}

			if( sence == 'delete' ){	//删除
				if( tabLen <=2 ){
					tool.message("底部tabbr最少2个","warning");
					return
				}
				tool.confirm("确认删除Tabbar吗？").then(()=>{
					_d.tabbarForm.tabbar_list.splice(index,1);
					_d.tabbar_index = 0;
				}).catch(()=>{})
			}
		}

		//重置菜单信息
		function resetCenterPage(){
			_d.center = tool.getWxappCenterPath()
			submitSave()
		}
		return{
			...toRefs(_d),
			submitSave,
			changeTab,
			getCurrentList,
			handleCusotmPage,
			setTabbarWidth,
			handleTabbar,
			resetCenterPage
		}
	}
}
</script>

<style lang="scss">
	.kd-slide-img{
		display: flex;
		align-items: center;
		cursor: pointer;
		&-li{
			width: 100px; 
			height: 100px;
			margin: 0 10px 0 0;
		}
	}
	
	.tabbar-cont{
		display: flex;
		align-items: center;
		width: 375px;
		height: 50px;
		justify-content: space-between;
		border: 1px solid #f4f4f4;
		padding-top: 6px;
		>div{
			width: 100%;
			display: flex;
		}
		&-item{
			text-align: center;
			height: 50px;
			line-height: 20px;
			cursor: pointer;
			
			i{
				font-size: 18px;
			}
			div{
				font-size: 12px;
			}
		}
	}
	
	.tabbar-cont-add{
		width: 56px;
		height: 50px;
		text-align: center;
		line-height: 50px;
		border: 1px solid #F4F4F4;
		position: absolute;
		margin-top: -50px;
		margin-left: 390px;
		cursor: pointer;
	}
	
	.tabbar-cont-ite-edit{
		border: 1px solid #F4F4F4;
		padding: 16px 0 8px 0;
		margin-bottom: 8px;
	}
	
	.mini-center-li{
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		cursor: pointer;
		width: 50%;
		
		&-menu{
			width: 200px;
			display: flex;
			align-items: center;
			.iconfont {
				margin-right: 10px;
			}
		}
		
		&-wxapp,&-app{
			width: 150px;
			font-size: 14px;
			height: 40px;
			line-height: 43px;
			color: #999;
		}	
	}
</style>
